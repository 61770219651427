const QUERIES = {
  USERS_LIST: 'users-list',
  SUBCATEGORIES_LIST: 'subcategories-list',
  SUBCATEGORIESB_LIST: 'subcategoriesB-list',
  CATEGORIES_LIST: 'categories-list',
  CATEGORIESB_LIST: 'categoriesB-list',
  ITEMS_LIST: 'items-list',
  ITEMSB_LIST: 'itemsB-list',
  CONCESSIONAIRES_LIST: 'concessionaires-list',
  CRITERION_LIST: 'criterion-list',
}

export {QUERIES}
