import axios from 'axios'
import {AuthModel, UserModel, VerifyCaptchaModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const token_name = process.env.REACT_APP_BASE_LAYOUT_CONFIG_KEY

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const CAPTCHA_URL = `${API_URL}/verify-recaptcha`

// Server should return AuthModel
export function login(email: string, password: string) {
  
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
    token_name,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, config)
}

export function verifyCaptcha(recaptchaToken: string) {
  
  return axios.post<VerifyCaptchaModel>(CAPTCHA_URL, {
    recaptchaToken,
  })
}
