import axios, { AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const UPLOAD_URL = `${API_URL}/upload`;
const DOWNLOAD_URL = `${API_URL}/export`;
const GraphicSc_URL = `${API_URL}/GraphicSc1`;

const uploadFileToBackend = (file: File, userId: number, fileType: string): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('userId', userId.toString());
  formData.append('file_type', fileType);
  return axios.post(`${UPLOAD_URL}`, formData);
};

const downloadFileToBackend = (fileName: string, fileType: string): Promise<AxiosResponse> => {
  const downloadUrl = `${DOWNLOAD_URL}/${fileType.toUpperCase()}/${fileName}`;
  return axios.get(downloadUrl, { responseType: 'blob' });
};

const requestGraphicSc = (periodo: string): Promise<AxiosResponse> => {
  const data = {'periodo':periodo};
  return axios.post(GraphicSc_URL, data, { responseType: 'json' });
};


export { uploadFileToBackend, downloadFileToBackend, requestGraphicSc };
