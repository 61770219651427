/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login, verifyCaptcha } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import ToastMessage from './toast/ToastMessage';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de email erroneo')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email es requerido'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password es requerida'),
  //recaptchaToken: Yup.string().required("Verificación de reCAPTCHA requerida"),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
 
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      
      try {
        if (recaptchaToken) {
          
          try {
            const API_URL = process.env.REACT_APP_API_URL
            const VERIFY_URL = `${API_URL}/verify-recaptcha`
            const response = await axios.post(VERIFY_URL, { recaptchaToken: recaptchaToken });
            console.log(response.data);
            const recaptchaData = response.data;
            const { data: verify } = await verifyCaptcha(recaptchaToken);
           
            //console.log(verify);
           //const verify = { success: true };
           if (verify.success) {
              // console.log(values.email);
              // console.log(values.password);
              const { data: auth } = await login(values.email, values.password);
              saveAuth(auth);
              // console.log(auth);
              const { data: user } = await getUserByToken(auth.api_token);
              setCurrentUser(user);
              setLoading(false);
              setShowToast(true);
              setMessage('Login exitoso');

            } else {
              console.error("Validación de reCAPTCHA fallida");
              setStatus("Validación de reCAPTCHA fallida");
              setSubmitting(false);
              setLoading(false);
              setIsFailed(true);
              setShowToast(true);
              setMessage('Validación de reCAPTCHA fallida');
            }

          } catch (error: any) {
            console.error('Error verifying reCAPTCHA:', error);
            setIsFailed(true);
            setShowToast(true);
            setMessage(error.response.data.error);
          }

        } else {
          console.error("Token de reCAPTCHA nulo");
          setStatus("Token de reCAPTCHA nulo");
          setSubmitting(false);
          setLoading(false);
          setIsFailed(true);
          setShowToast(true);
          setMessage('Token de reCAPTCHA nulo');
        }
      } catch (error: any) {
        console.error(error);
        setMessage(error.response.data.error);
        saveAuth(undefined);
        setStatus(error.response.data.error);
        setSubmitting(false);
        setLoading(false);
        setIsFailed(true);
        setShowToast(true);

      }
    },

  })

  return (
    <>
    {showToast && <ToastMessage isFailed={isFailed} setShowToast={setShowToast} showToast={showToast} messageResponse={message} />}
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >

      {/* begin::Separator */}
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7 text-light'>Bienvenido a Red Trucks & Buses</span>
      </div>
      {/* end::Separator */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
          Ingrese su <strong>correo electrónico, contraseña y captcha</strong>.
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-light'>Correo electrónico</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent text-light',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-light fs-6 mb-0'>Contraseña</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent text-light',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary text-light'>
        ¿Olvido su contraseña?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}
      <div className='fv-row mb-3'>
      {/*<div className='fv-col md-2'></div>
      <div className='fv-col md-8'> */}
      <div className='fv-row mb-3 d-flex justify-content-center'>
        <ReCAPTCHA
          sitekey='6LeXRQ4pAAAAAI0EkXiEhXstB1kYlRNP7v_ayZuC'
          onChange={(token) => setRecaptchaToken(token)}
        />
      </div>
      <div className='fv-col md-2'></div>
      </div>
      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label text-light'>Ingresar</span>}
          {loading && (
            <span className='indicator-progress text-light' style={{ display: 'block' }}>
              Por favor espere...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

    </form>
    </>
  )
}
