import { FC } from "react";
import {Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useState, useEffect } from "react";
import { getLoginValidation } from "./core/_requests";

const LoginValidationPage: FC = () => {
    const [loading, setLoading] = useState(true)
    const [validation, setValidation] = useState(false)
    const [responseStatus, setResponseStatus] = useState<string>('')

    const { encryptedEmail, hashCode } = useParams<{ encryptedEmail: string; hashCode: string }>();
    const handleLoginValidation = async (email: string, hash: string) => {
        try {
            const response = await getLoginValidation(email, hash);
            if (response.status === 200) {
                setValidation(true);
                setResponseStatus(response.data.error);
            }
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setResponseStatus(error.response.data.error);
            }
            setLoading(false);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    }

    useEffect(() => {
        if (encryptedEmail && hashCode) {
            handleLoginValidation(encryptedEmail, hashCode);
          }
      }, [encryptedEmail, hashCode]);

    return (
        <>
        <div className="d-flex justify-content-center align-items-center flex-column h-100 " style={{backgroundColor: 'rgba(0,0,0,0.9)'}}>
        {/* <h1 className="fw-bolder fs-2hx mb-4 text-white">Verificando Email...</h1> */}
        {loading ? 
        <div className="d-flex justify-content-center flex-column align-items-center">
        <h1 className="fw-bolder fs-2hx mb-5 text-white">Verificando Email...</h1>
        <span className='mb-4 spinner-border spinner-border-sm align-middle ms-2 text-white'></span>
        </div>
        :
        validation ? <span className="fw-bold fs-6 text-white mb-9 bg-success p-2 rounded fs-3">{responseStatus}</span>
        : <span className="fw-bold fs-6 text-white mb-9 bg-danger p-2 rounded fs-3">{responseStatus}</span>
        }
        <div className="mb-5 mt-5">
            <img src={toAbsoluteUrl('/media/logos/logo-mb-grande.png')} className="mw-100 mh-300px theme-light-show" alt="" />
            <img src={toAbsoluteUrl('/media/logos/logo-mb-grande.png')} className="mw-100 mh-300px theme-dark-show" alt="" />
        </div>
        <div className="mt-5">
            <Link to="/auth" className="btn btn-sm btn-primary">
            Volver al Inicio
            </Link>
        </div>
        </div>
        </>
    );
}

export default  LoginValidationPage