import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

interface ToastMessageProps {
    showToast: boolean;
    messageResponse: string;
    setShowToast: (value: boolean) => void;
    isFailed: boolean;
}

const ToastMessage: React.FC<ToastMessageProps> = ({isFailed=false, setShowToast, showToast, messageResponse }) => {
    return (
        <div className='d-flex justify-content-end pb-3' style={{ position: 'fixed', zIndex: 5, right: 5, top: 120, }}>
            <Row>
                <Col xs={6}>
                {messageResponse !== '' && (
                    <Toast bg={isFailed ? 'danger' : 'success'} className='text-white text-center' onClose={() => setShowToast(false)} show={showToast} delay={5000} >
                        
                            <Toast.Body>{messageResponse}</Toast.Body>
                        
                    </Toast>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default ToastMessage;
