/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menú</span>
        </div>
      </div>
      <SidebarMenuItem to='/apps/concessionaire-management/concessionaires' title='Concesionarios' hasBullet={true} />
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Configuración'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='StarClass A' hasBullet={true}>
          <SidebarMenuItem to='/apps/list-management/list' title='Listado' hasBullet={true} />
          <SidebarMenuItem to='/apps/category-management/categories' title='Categorías' hasBullet={true} />
          <SidebarMenuItem to='/apps/subcategory-management/subcategories' title='Subcategorías' hasBullet={true} />
          <SidebarMenuItem to='/apps/items-management/items' title='Ítems' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='StarClass B' hasBullet={true}>
          <SidebarMenuItem to='/apps/listB-management/listB' title='Listado' hasBullet={true} />
          <SidebarMenuItem to='/apps/categoryB-management/categoriesB' title='Categorías' hasBullet={true} />
          <SidebarMenuItem to='/apps/subcategoryB-management/subcategoriesB' title='Subcategorías' hasBullet={true} />
          <SidebarMenuItem to='/apps/itemsB-management/itemsB' title='Ítems' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItem to='/apps/percentageCriterion-management/percentage' title='Porcentajes de Criterio' hasBullet={true} />

        {/* <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub> */}
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='' title='Reportes' hasBullet={true}>
        <SidebarMenuItem to='/apps/reports/resumenSc' title='Resumen SC' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='' title='Graficos' hasBullet={true}>
        <SidebarMenuItem to='/apps/graphics-management/graphic' title='Objetivo Repuestos' hasBullet={true} />
        <SidebarMenuItem to='/apps/graphics2-management/graphic' title='Objetivo Ventas' hasBullet={true} />
        {/* <SidebarMenuItem to='/apps/graphicSc-management/graphic' title='Resumen Sc' hasBullet={true} /> */}
        <SidebarMenuItem to='/apps/graphicScCon-management/graphic' title='Cuentas' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem to='/apps/downloadA-management/download' title='Exportación' hasBullet={true} />
        <SidebarMenuItem to='/apps/import-management/import' title='Importación' hasBullet={true} />
        
      <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Clasificación' hasBullet={true}>
        <SidebarMenuItem to='/apps/criterion-management/criterion' title='General' hasBullet={true} />
        <SidebarMenuItem to='/apps/percentageCriterion-management/percentage' title='Especial'hasBullet={true} />
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItemWithSub
        to='/crafted/usuarios'
        title='Usuarios'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Usuarios' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Perfiles' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='Usuarios'
        fontIcon='bi-layers'
      />
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
