/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget1,
  ChartsWidget6,
  ChartsWidget1,
  ChartsWidget2,
} from '../../../_metronic/partials/widgets'
import GraphicsComponentSc from '../../modules/apps/graphicSc-management/graphic/GraphicsComponentSc';

const DashboardPage: FC = () => (
  <>
    <GraphicsComponentSc />


  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
